import * as React from 'react'

import { Header } from './header'

export const Shell: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className="shell">
      <Header />
      {children}
    </div>
  )
}
