import './header.scss'

import { useLocation } from '@reach/router'
import { graphql, Link, useStaticQuery } from 'gatsby'
import * as React from 'react'

import { useScrollPosition } from '../hooks/useScrollPosition'
import { Routes } from '../utils/routes'
import { SocialLinks } from './socialLinks'

export const Header: React.FC = () => {
  const data = useStaticQuery<Queries.PrismicHeaderQuery>(graphql`
    query PrismicHeader {
      prismicShell {
        data {
          title
          links {
            link_text
            link {
              url
            }
          }
        }
      }
    }
  `)

  const scrollPosition = useScrollPosition()

  const [menuOpen, setMenuOpen] = React.useState(false)

  const { pathname } = useLocation()

  React.useEffect(() => {
    setMenuOpen(false)
  }, [pathname])

  React.useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden'

      return () => {
        document.body.style.overflow = 'auto'
      }
    }
  }, [menuOpen])

  const navMenuId = React.useId()
  const navButtonId = React.useId()

  return (
    <>
      <nav
        className="nav-menu"
        aria-hidden={!menuOpen}
        aria-expanded={menuOpen}
        data-open={menuOpen}
        id={navMenuId}
        role="navigation"
        aria-label="Navigation menu"
      >
        <div className="nav-menu__inner">
          {data.prismicShell?.data.links.map((link) => (
            <div className="nav-menu__link" key={link.link?.url}>
              <Link to={link.link?.url || '/'} onClick={() => setMenuOpen(false)} activeClassName="active" tabIndex={menuOpen ? 0 : -1}>
                {link.link_text}
              </Link>
            </div>
          ))}
          <div className="nav-menu__link" onClick={() => setMenuOpen(false)}>
            {menuOpen && <SocialLinks />}
          </div>
        </div>
      </nav>

      <header className="header" data-at-top={(!scrollPosition || scrollPosition < 1) && !menuOpen}>
        <div className="header__inner">
          <Link className="header__title" to={Routes.home} onClick={() => setMenuOpen(false)}>
            {data.prismicShell?.data.title}
          </Link>
          <button
            className="header__nav-button"
            onClick={() => setMenuOpen(!menuOpen)}
            data-open={menuOpen}
            aria-label={`${menuOpen ? 'Close' : 'Open'} Menu`}
            aria-expanded={menuOpen}
            aria-controls={navMenuId}
            id={navButtonId}
          >
            <div className="header__nav-button-text">{menuOpen ? 'Close' : 'Menu'}</div> <div className="header__nav-button-line" />
          </button>
        </div>
      </header>
    </>
  )
}
