import { Globals, useEventListener } from '@rocketmakers/armstrong-edge'
import * as React from 'react'

export const useScrollPosition = (onScrollCallback?: (scrollPosition?: number) => void) => {
  const [scrollPosition, setScrollPosition] = React.useState(Globals.Document?.scrollingElement?.scrollTop)

  const onScroll = React.useCallback(() => {
    onScrollCallback?.(Globals.Document?.scrollingElement?.scrollTop)
    setScrollPosition(Globals.Document?.scrollingElement?.scrollTop)
  }, [onScrollCallback])

  useEventListener('scroll', onScroll)
  useEventListener('resize', onScroll)

  React.useEffect(() => onScroll, [])

  return scrollPosition
}

export const useScrollHeight = () => {
  const [scrollHeight, setScrollHeight] = React.useState(Globals.Document?.scrollingElement?.scrollTop)

  const calculate = React.useCallback(() => {
    setScrollHeight(Globals.Document?.body?.offsetHeight)
  }, [])

  useEventListener('scroll', calculate)
  useEventListener('resize', calculate)

  React.useEffect(() => calculate, [])

  return scrollHeight
}
