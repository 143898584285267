module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"simonbenjaminsound","accessToken":"MC5aZU43LXhFQUFCOEFpU1pH.77-977-977-9UO-_vTl_cTLvv73vv73vv70S77-9IO-_ve-_vXLvv73vv70N77-977-9FlHvv70iWu-_ve-_vU0i"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Simon Benjamin Sound","short_name":"Simon Benjamin","start_url":"/","background_color":"#373260","theme_color":"#D9EB43","display":"standalone","icon":"src/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"55e0a73ad52cd8cd647e6c43752b90ad"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
