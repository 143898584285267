import './socialLinks.scss'

import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'

import { Icons } from '../utils/icons'

export interface ISocialLinksProps {
  emailFull?: boolean
}

export const SocialLinks: React.FC<ISocialLinksProps> = ({ emailFull }) => {
  const data = useStaticQuery<Queries.SocialLinksQuery>(graphql`
    query SocialLinks {
      prismicSocialLinks {
        data {
          email_address
          instagram {
            url
          }
          linked_in {
            url
          }
        }
      }
    }
  `)

  return (
    <div className="social-links">
      {data.prismicSocialLinks?.data.email_address ? (
        <a target="_blank" rel="noopener noreferrer" href={`mailto:${data.prismicSocialLinks.data.email_address}`} aria-label="Email Link">
          <Icons.Email />
          {emailFull && <> {data.prismicSocialLinks.data.email_address}</>}
        </a>
      ) : undefined}

      {data.prismicSocialLinks?.data.instagram?.url ? (
        <a target="_blank" rel="noopener noreferrer" href={data.prismicSocialLinks.data.instagram.url} aria-label="Instagram Link">
          <Icons.Instagram />
        </a>
      ) : undefined}

      {data.prismicSocialLinks?.data.linked_in?.url ? (
        <a target="_blank" rel="noopener noreferrer" href={data.prismicSocialLinks.data.linked_in.url} aria-label="LinkedIn Link">
          <Icons.LinkedIn />
        </a>
      ) : undefined}
    </div>
  )
}
