// eslint-disable-next-line import/no-duplicates
import Credits from '@mui/icons-material/Accessibility'
// eslint-disable-next-line import/no-duplicates
import About from '@mui/icons-material/Accessibility'
import Work from '@mui/icons-material/AudioFile'
import Email from '@mui/icons-material/Email'
import GetInTouch from '@mui/icons-material/EmojiPeople'
import Error from '@mui/icons-material/Error'
import Quote from '@mui/icons-material/FormatQuote'
import Info from '@mui/icons-material/HelpCenter'
import Instagram from '@mui/icons-material/Instagram'
import LinkedIn from '@mui/icons-material/LinkedIn'
import Send from '@mui/icons-material/Send'
import Video from '@mui/icons-material/Videocam'
import Showreel from '@mui/icons-material/VideocamOutlined'

export const Icons = {
  About,
  Error,
  Credits,
  Showreel,
  Quote,
  Work,
  Video,
  Info,
  GetInTouch,
  LinkedIn,
  Instagram,
  Email,
  Send,
}

export type IconDefinition = keyof typeof Icons
